import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Food Delivery Website Template | Food Order Website Template | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/online-food-delivery/"
    metaDescription="QuickBite is a food delivery app template that allows you to design a full-featured food delivery website in no time. Explore the template now."
    description="
    h2:Introducing QuickBite; a gorgeous food order website template
    <br/>
    Are you looking for the easiest way to design a food delivery website? QuickBite, our food delivery website design template, has everything you could possibly need! In this food delivery web app template, each screen is fully customizable and has a contemporary look that is highly engaging and based on principles of good UX design.
    <br/>
    h3:The perfect starting point for your food delivery website
    <br/>
    If you're planning to launch your own food delivery website and you're not a professional designer, the QuickBite template is the perfect starting point for <a:https://uizard.io/prototyping/>UI prototyping</a> your design. The template comes with a beautiful design, a complete order checkout flow, and is easy to customize. All you need to do is to replace the text and images with your own, add your logo and your own branding through Uizard's theme creator, and voila! You can validate your vision before kickstarting and growing your business. 
    <br/>
    h3:Everything is included in this food delivery app template
    <br/>
    QuickBite is a food delivery app template that allows you to design a full-featured food delivery website in no time. You'll find a beautiful home page, a page to showcase restaurant search result, an order checkout flow, order tracking page, and everything you would expect from your favorite food businesses. This template is also available in a mobile app format so you can easily design everything for your FoodTech business.
    "
    pages={[
      "A food delivery landing page",
      "An order page",
      "A checkout flow",
      "An order confirmation page",
      "An order status page",
      "And more!",
    ]}
    projectCode="0oQmMOAeqaUyGrRv5glB"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of QuickBite, an online food delivery website: home page"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of QuickBite, an online food delivery website: restaurant page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of QuickBite, an online food delivery website: all restaurants search page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of QuickBite, an online food delivery website: checkout page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary for the template QuickBite, an online food delivery website"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/Quick-Bite/Quick-Bite-Cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/Quick-Bite/Quick-Bite-Restaurant-page.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/Quick-Bite/Quick-Bite-Restaurants.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/Quick-Bite/Quick-Bite-Checkout.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/Quick-Bite/Quick-Bite-Summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
